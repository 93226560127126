export const blogsInitialState = {
    title: "",
    description: "",
    details: null,
    html: "",
    author: "",
    profile: ""
}

export const blogReducer = (state, action) => {
    switch (action.type){
        case "title":
            return {...state, title: action.payload};
        case "description":
            return {...state, description: action.payload};
        case "details":
            return {...state, details: action.payload};
        case "html":
            return {...state, html: action.payload};
        case "author":
            return {...state, author: action.payload};
        case "profile":
            return {...state, profile: action.payload};
        default:
            return {...state};
    }
}