/* eslint-disable */
import { useState } from "react";
import "./Header.css"
import {Link} from 'react-router-dom';
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Auth from "../../pages/Auth";
import Signup from "../../pages/Signup";
import { useSelector } from "react-redux";
import LoggedInButton from "./LoggedInButton";
import OutsideClickHandler from "react-outside-click-handler";
const Header = () => {
    const [showLanguagesDropdown, setShowLanguagesDropdown] = useState(false);
    const [showAuth, setShowAuth] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const user = useSelector((state) => state.user);

    return(
        <header className="header-container">
            <div className="header-content-head">
                <div className="header-content">
                    <div className="d-flex flex-center-start">
                        <Link to="/"><img src="/static/icons/logo.png" className="header-logo-icon" alt="Logo" /></Link>
                        <div className="form-group-searchbox">
                            <img src="/static/icons/loop.png" className="header-loop" alt="Looop"/>
                            <input type="text" className="header-search-input-box" placeholder="ძებნა"/>
                        </div>
                    </div>
                    <div className="d-flex flex-center-enn">
                        {user.isLoggedIn == false && <button onClick={() => setShowAuth(true)} className="login-button"><img src="/static/icons/user.svg" alt="profile"/><span>ავტორიზაცია</span></button>}
                        {user.isLoggedIn == true && <LoggedInButton />}
                        <div className="language-switcher-container">
                            <div className="selected-language-box" onClick={() => setShowLanguagesDropdown(!showLanguagesDropdown)}>
                                <div className="selected-language-content">
                                    <img src="/static/icons/geo-flag.svg" alt="geo-flag" />
                                    <p>ქართული</p>
                                </div>
                                <img src="/static/icons/down-icon.svg" alt="down" />
                            </div>
                            {showLanguagesDropdown && <OutsideClickHandler onOutsideClick={() => setShowLanguagesDropdown(false)}>
                                <div className="choose-language-dropdown">
                                    <div className="language-list-card" onClick={() => setShowLanguagesDropdown(false)}>
                                        <img src="/static/icons/eng-flag.svg" alt="geo-flag" />
                                        <p>English</p>
                                    </div>
                                </div>    
                            </OutsideClickHandler>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-divider"></div>
            <div className="navigation-container">
                <nav className="navbar-content">
                    <ul className="navbar-list">
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/">მთავარი</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/catalogue">კატალოგი</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/about-us">ჩვენს შესახებ</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/blogs">ბლოგი</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/calculator">კალკულატორი</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/contact">კონტაქტი</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/dealer-page">დილერი გვერდი</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/auto-market">ავტო მარკეტი</NavLink></li>
                        <li className="navbar-item"><NavLink activeClassName="active-tab" exact to="/admin/blogs">ბლოგის დამატება</NavLink></li>
                    </ul>
                </nav>
            </div>
            {showAuth && <Auth setShowAuth={setShowAuth} setShowSignup={setShowSignup} />}
            {showSignup && <Signup setShowAuth={setShowAuth} setShowSignup={setShowSignup} />}
        </header>
    )
}

export default Header