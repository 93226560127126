import { useEffect } from "react";
import MainPageContact from "../components/MainPage/MainPageContact/MainPageContact"
import {Link} from 'react-router-dom';

const Contact = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
    }, [])
    
    return(
        <div className="contact-page-container">
            <div className="about-us-page-map">
                <Link to="/main">მთავარი</Link>
                <span>/</span>
                <Link to="/calculator">კონტაქტი</Link>
            </div>
            <div className="main-page-contacts">
                <h3 className="section-title">კონტაქტი</h3>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2104.2662599072855!2d44.768262881593394!3d41.76620437634921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40446dfe78aed8a9%3A0xe14a15e2ab5249d4!2zQ2Fyc3BhY2Ug4oCiIOGDpeGDkOGDoOGDoeGDpOGDlOGDmOGDoeGDmA!5e0!3m2!1sen!2sge!4v1727348755975!5m2!1sen!2sge" width="100%" height="750" style={{border: 0, borderRadius: "20px", marginBottom: "84px"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <MainPageContact />
        </div>
    )
}

export default Contact