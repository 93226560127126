export const core = {
    getEmptyParamNames(obj) {
        // Initialize an array to hold the names of empty parameters
        const emptyParams = [];
      
        // Iterate through the object properties
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // Check if the value of the property is an empty string
            if (obj[key] === "") {
              // If it's an empty string, add the property name to the array
              emptyParams.push(key);
            }
          }
        }
      
        // Return the array of empty parameter names
        return emptyParams;
    }
}