import { Link } from "react-router-dom/cjs/react-router-dom";
import "./PopularBrandsList.css";

const PopularBrandsList = () => {
    return(
        <div className="popular-brands-list-container">
            <div className="popular-brands-list-banner"></div>
            <div className="vehicle-type-selector-head">
                <h3 className="vehicle-type-selector-title">აღმოაჩინე პრემიუმ ბრენდები</h3>
                <Link className={"view-all-button"} to="/catalogue">ყველას ნახვა</Link>
            </div>
            <div className="premium-brands-list">
                <div className="popular-brand-item">
                    <div className="brand-image-box">
                        <img src="/static/icons/audi.png" className="premium-brand-image" alt="brand logo" />
                    </div>
                    <span className="permium-brand-name">Audi</span>
                </div>
                <div className="popular-brand-item">
                    <div className="brand-image-box">
                        <img src="/static/icons/porsche.png" className="premium-brand-image" alt="brand logo" />
                    </div>
                    <span className="permium-brand-name">Porsche</span>
                </div>
                <div className="popular-brand-item">
                    <div className="brand-image-box">
                        <img src="/static/icons/mercedes.png" className="premium-brand-image" alt="brand logo" />
                    </div>
                    <span className="permium-brand-name">Mercedes Benz</span>
                </div>
                <div className="popular-brand-item">
                    <div className="brand-image-box">
                        <img src="/static/icons/tesla.png" className="premium-brand-image" alt="brand logo" />
                    </div>
                    <span className="permium-brand-name">Tesla</span>
                </div>
                <div className="popular-brand-item">
                    <div className="brand-image-box">
                        <img src="/static/icons/ford.png" className="premium-brand-image" alt="brand logo" />
                    </div>
                    <span className="permium-brand-name">Ford</span>
                </div>
                <div className="popular-brand-item">
                    <div className="brand-image-box">
                        <img src="/static/icons/vw.png" className="premium-brand-image" alt="brand logo" />
                    </div>
                    <span className="permium-brand-name">Volkswagen</span>
                </div>
            </div>
        </div>
    )
}

export default PopularBrandsList