import {Link} from 'react-router-dom';
import "../stylesheet/aboutus.css"
import { useEffect } from 'react';

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
    }, [])

    return(
        <>
            <div className="about-us-container">
                <div className="about-us-page-map">
                    <Link to="/main">მთავარი</Link>
                    <span>/</span>
                    <Link to="/about-us">ჩვენს შესახებ</Link>
                </div>
                <h3 className='about-us-title'>ჩვენ შესახებ</h3>
                <div className='about-us-content'>
                    <div className='about-us-description-text'>
                        <p className='about-us-page-details'>
                            Carspace დაარსდა 2020 წელს, მაღალკვალიფიციური გუნდის მიერ, რომელსაც გააჩნია 10-წლიანი გამოცდილება გადაზიდვებსა და ავტო იმპორტში. 
                            ჩვენი მთავარი მიზანია, მომხმარებლებისთვის ავტომობილებისა და სპეცტექნიკის შეძენისა და ტრანსპორტირების პროცესი მაქსიმალურად უსაფრთხო, 
                            მარტივი და ხელმისაწვდომი გავხადოთ.
                        </p>
                        <p className='about-us-page-details' style={{marginBottom: 0}}>
                            ჩვენი გუნდი ამაყობს იმით, რომ უკვე შევასრულეთ 10,000-ზე მეტი წარმატებული გადაზიდვა, რაც ჩვენს მომხმარებლებს აძლევს ნდობისა და უსაფრთხოების 
                            გარანტიას. გარდა ამისა, ვაძლევთ მომხმარებლებს საშუალებას, შეამოწმონ თავიანთი არჩეული ავტომობილების ისტორია ისეთ სანდო პლატფორმებზე, როგორიცაა 
                            Carfax და Autocheck, რაც ეხმარება მათ რისკების შემცირებაში და ინფორმირებული გადაწყვეტილების მიღებაში.
                        </p>
                        <p className='about-us-page-details'>
                            ჩვენი მომხმარებლები ასევე სარგებლობენ წვდომით მსოფლიოს წამყვან აუქციონებზე, როგორიცაა Copart, IAAI, Manheim, Pipeline, Cars.com და Adesa. აქ, 
                            მომხმარებლებს შესაძლებლობა აქვთ შეიძინონ როგორც დაზიანებული, ასევე დაუზიანებელი ავტომობილები.
                        </p>
                        <div className='about-us-page-hr'></div>
                        <h4 className='about-us-subtitle'>Carspace უზრუნველყოფს სხვადასხვა ტიპის სატრანსპორტო</h4>
                        <h4 className='about-us-subtitle'>საშუალებების გადაზიდვას, მათ შორის:</h4>
                        <ul className='about-us-content-lists'>
                            <li>მოტოციკლეტები</li>
                            <li>მსუბუქი ავტომობილები</li>
                            <li>გაბარიტული ავტომობილები</li>
                            <li>მძიმე სპეცტექნიკა</li>
                            <li>მსუბუქი სპეცტექნიკა</li>
                            <li>კვადროციკლები</li>
                            <li>წყლის სკუტერები</li>
                        </ul>
                        <p className='about-us-page-details'>
                            ჩვენი დაბალი ტრანსპორტირების ტარიფები და მაღალი ხარისხის მომსახურება უზრუნველყოფს, რომ ყველა ტიპის ტრანსპორტი უსაფრთხოდ და 
                            დროულად მიაღწევს დანიშნულების ადგილს. ჩვენთვის განსაკუთრებით მნიშვნელოვანი მომხმარებლები არიან ავტო დილერები, რომლებსაც ვთავაზობთ 
                            განსაკუთრებულ პირობებსა და სერვისებს.
                        </p>
                        <p className='about-us-page-details'>
                            Carspace-ს გუნდი ყოველთვის მზადაა უზრუნველყოს უმაღლესი ხარისხის მომსახურება, სანდოობა და მომხმარებლის კმაყოფილება, რაც ჩვენი მთავარი პრიორიტეტია.
                        </p>
                    </div>
                    <div className='about-us-banner'></div>
                </div>
                <div className='about-us-page-banners-container'>
                    <div className='banner-first-col'>
                        <div style={{backgroundColor: "#405FF2", color: "white", height: "300px", padding: "42px 32px", fontSize: "30px", fontWeight: "500"}}>
                            4 <br /> წელი ბაზარზე
                        </div>
                        <div style={{backgroundImage: `url('/static/banner-1.png')`, height: "217px", marginTop: "29px"}}>

                        </div>
                    </div>
                    <div className='banner-second-col' style={{backgroundImage: `url('/static/banner-2.png')`, height: "546px"}}>

                    </div>
                    <div className='banner-third-col'>
                        <div style={{backgroundImage: `url('/static/banner-3.png')`, height: "300px"}}>

                        </div>
                        <div className='banner-with-two-col'>
                            <div style={{backgroundImage: `url('/static/banner-4.png')`, color: "white", width: "208px", height: "217px", marginTop: "29px", padding: "42px 32px", fontSize: "30px", fontWeight: "500"}}>

                            </div>
                            <div style={{backgroundImage: `url('/static/banner-5.png')`, color: "white", width: "320px", height: "217px", marginTop: "29px", padding: "42px 32px", fontSize: "30px", fontWeight: "500"}}>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='services-counter-container'>
                    <div className='about-us-service'>
                        <p className='service-count-value'>834</p>
                        <p className='service-count-label'>გაყიდული მანქანები</p>
                    </div>
                    <div className='about-us-service'>
                        <p className='service-count-value'>834</p>
                        <p className='service-count-label'>გაყიდული მანქანები</p>
                    </div>
                    <div className='about-us-service'>
                        <p className='service-count-value'>834</p>
                        <p className='service-count-label'>გაყიდული მანქანები</p>
                    </div>
                    <div className='about-us-service'>
                        <p className='service-count-value'>834</p>
                        <p className='service-count-label'>გაყიდული მანქანები</p>
                    </div>
                    <div className='about-us-service'>
                        <p className='service-count-value'>834</p>
                        <p className='service-count-label'>გაყიდული მანქანები</p>
                    </div>
                </div>
            </div>
            <div className='about-us-page-hr'></div>
        </>
    )
}

export default AboutUs