import React from "react";
import { RichUtils } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createImagePlugin from "draft-js-image-plugin";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import "./TextEditor.css"

const imagePlugin = createImagePlugin();

const TextEditor = ({editorState, setEditorState, setHtmlContent, setConvertedToHtml}) => {
  // Handle key commands (e.g., bold, italic)
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  // Toggle inline styles (bold, italic, underline)
  const toggleInlineStyle = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  // Convert editor content to HTML
  const convertContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    setHtmlContent(html);
    setConvertedToHtml(true)
  };

  // Function to handle image uploads
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const src = e.target.result;
      setEditorState(imagePlugin.addImage(editorState, src));
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="text-editor-container">
        <div className="text-editor-body">
          <div className="toolbar">
              <button onClick={() => toggleInlineStyle("BOLD")}>B</button>
              <button onClick={() => toggleInlineStyle("ITALIC")}>I</button>
              <button onClick={() => toggleInlineStyle("UNDERLINE")}>U</button>
              <button onClick={() => toggleBlockType("header-one")}>H1</button>
              <button onClick={() => toggleBlockType("header-two")}>H2</button>
              <button onClick={() => toggleBlockType("header-three")}>H3</button>
              <button onClick={() => toggleBlockType("header-four")}>H4</button>
              <button onClick={() => toggleBlockType("header-five")}>H5</button>
              <button onClick={() => toggleBlockType("header-six")}>H6</button>
          </div>

          <div className="text-editor-box">
              <Editor
                  editorState={editorState}
                  onChange={setEditorState}
                  handleKeyCommand={handleKeyCommand}
                  plugins={[imagePlugin]}
              />
          </div>
        </div>
        <input hidden type="file" id="upload-image" onChange={handleImageUpload} accept="image/*" />
        <label className="upload-image-button" htmlFor="upload-image">სურათის ატვირთვა</label>
        <button onClick={convertContentToHTML} className="save-content-button">შენახვა</button>
    </div>
  );
};

export default TextEditor;