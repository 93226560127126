export const filterInitialState = {
    make: {code: "", description: ""}, 
    model: "", 
    yearFrom: "1920",
    yearTo: new Date().getFullYear(), 
    millageFrom: "0",
    millageTo: "999999", 
    condition: "", 
    fuel: "", 
    transmission: "", 
    drive: "", 
    sellType: "",
    keyword: ""
}

export const filterReducer = (state, action) => {
    switch (action.type){
        case "make": 
            return{...state, make: {code: action.payload == "default" ? "" : action.payload.code, description: action.payload == "default" ? "" : action.payload.description}};
        case "model":
            return {...state, model: action.payload == "default" ? "" : action.payload};
        case "year_from":
            return {...state, yearFrom: action.payload};
        case "year_to":
            return {...state, yearTo: action.payload};
        case "millage_from":
            return {...state, millageFrom: action.payload};
        case "millage_to":
            return {...state, millageTo: action.payload};
        case "condition":
            return {...state, condition: action.payload == "default" ? "" : action.payload};
        case "fuel":
            return {...state, fuel: action.payload == "default" ? "" : action.payload};
        case "transmission":
            return {...state, transmission: action.payload == "default" ? "" : action.payload};
        case "drive":
            return {...state, drive: action.payload == "default" ? "" : action.payload};
        case "keyword":
            return {...state, keyword: action.payload
            };
        case "sellType":
            return {...state, sellType: action.payload == "default" ? "" : action.payload};
    }
}