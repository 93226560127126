export const fuelTypes = [
    {
        description: "Gas",
        code: "GAS"
    },
    {
        description: "Hybrid",
        code: "HYBRID ENGINE"
    },
    {
        description: "Electric",
        code: "ELECTRIC"
    },
    {
        description: "Diesel",
        code: "DIESEL"
    }
]

export const driveWheels = [
    {
        description: "4x4 w/Front Whl Drv",
        code: "4x4 w/Front Whl Drv"
    },
    {
        description: "4x4 w/Rear Wheel Drv",
        code: "4x4 w/Rear Wheel Drv"
    },
    {
        description: "All wheel drive",
        code: "All wheel drive"
    },
    {
        description: "Front-wheel Drive",
        code: "Front-wheel Drive"
    },
    {
        description: "Rear-wheel Drive",
        code: "Rear-wheel Drive"
    }
]