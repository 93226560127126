export const initSignup = {
    name: "",
    surname: "",
    email: "",
    password: "",
    repeatPassword: "",
    birthDate: "",
    gender: "female",
    phone: "",
    verificationCode: "",
    acceptTerms: false,
    acceptConfidential: false,
    idNumber: "",
    username: ""
}

export const signupReducer = (state, action) => {
    switch(action.type){
        case "name":
            return {...state, name: action.payload};
        case "surname": 
            return {...state, surname: action.payload};
        case "email": 
            return {...state, email: action.payload};
        case "password": 
            return {...state, password: action.payload};
        case "repeat_password": 
            return {...state, repeatPassword: action.payload};
        case "birth_date": 
            return {...state, birthDate: action.payload};
        case "gender": 
            return {...state, gender: action.payload};
        case "phone": 
            return {...state, phone: action.payload};
        case "verification_code": 
            return {...state, verificationCode: action.payload};
        case "accept_terms": 
            return {...state, acceptTerms: action.payload};
        case "accept_confidential": 
            return {...state, acceptConfidential: action.payload};
        case "id_number": 
            return {...state, idNumber: action.payload};
        case "username": 
            return {...state, username: action.payload};
        default:
            return {...state}
    }
}