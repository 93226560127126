import axios from "axios";
import "./Filter.css";
import { useEffect, useState } from "react";
import MarkSelector from "./MarkSelector/MarkSelector";
import MillageSelector from "./MarkSelector/MillageSelector";
import YearsSelector from "./MarkSelector/YearsSelector";
import {fuelTypes, driveWheels} from "./fuelTypes"
import { useSelector } from "react-redux";

const Filter = ({setLoading, setCarsList, submitSearch, setUseFilter, setFormData, formData, submitSearchKeyword}) => {
    const date = new Date();
    const [marks, setMarks] = useState([]);
    const [models, setModels] = useState([]);
    const [activeModels, setActiveModels] = useState([]);
    const [damageTypes, setDamageTypes] = useState([]);
    const [showSearchAdditionalSection, setShowSearchAdditionalSection] = useState(false);
    const token = useSelector((state) => state.user.token);
    
    const loaders = {
        async filterResult(page){
            setUseFilter(true);
            submitSearch();
        },
        searchByKeyword(){
            setUseFilter(true);
            submitSearchKeyword(token);
        },
        async getMakers(){
            const response = await axios.post('/api/vehicles/make');
            let data = response.data.data[0].make.filter((car) => car.type === "AUTOMOBILE");
            data.sort((a, b) => {
                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                  return -1;
                }
                if (a.description.toLowerCase() > b.description.toLowerCase()) {
                  return 1;
                }
                return 0;
            });
            setMarks(data.sort());
        },
        async getDamageTypes(){
            const response = await axios.post('/api/vehicles/damage-types');
            const data = response.data.data;
            setDamageTypes(data)
        },
        async getModels(){
            const response = await axios.post('/api/vehicles/models');
            const data = response.data.data[0].vehicleMakeandModels.filter((car) => car.type === "AUTOMOBILE");
            setModels(data);
        }
    }

    useEffect(() => {
        loaders.getMakers();
        loaders.getModels();
        loaders.getDamageTypes();
    }, []);

    return(
        <div className="catalogue-page-filter-container">
            <div className="catalogue-filter-row">
                <div className="filters-items-list">
                    <div className="catalogue-filter-item-box">
                        <MarkSelector 
                            active={formData.make.description}
                            data={marks}
                            parameterName={"description"}
                            onChangeFunction={(data) => {
                                setFormData({type: "make", payload: data});
                                let activeModelsList = models.filter((m) => m.group == data.code);
                                activeModelsList.sort((a, b) => {
                                    if (a.description.toLowerCase() < b.description.toLowerCase()) {
                                      return -1;
                                    }
                                    if (a.description.toLowerCase() > b.description.toLowerCase()) {
                                      return 1;
                                    }
                                    return 0;
                                });
                                const uniqueArray = activeModelsList.filter((item, index, self) => 
                                    index === self.findIndex((obj) => obj.description === item.description)
                                );
                                setActiveModels(uniqueArray);
                            }}
                            placeHolder={"მწარმოებელი"}
                            disabled={false}
                            disabledBtnTitle={""}
                            type="mark"
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="catalogue-filter-item-box">
                        <MarkSelector 
                            active={formData.model}
                            data={activeModels}
                            parameterName={"description"}
                            onChangeFunction={(data) => {
                                setFormData({type: "model", payload: data.code});
                            }}
                            placeHolder={"მოდელი"}
                            disabled={formData.make === ""}
                            disabledBtnTitle={"Please select make"}
                            type="models"
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="catalogue-filter-item-box">
                        <MillageSelector 
                            active={(formData.millageFrom == "0" && formData.millageTo == "999999") ? "all" : `${formData.millageFrom} - ${formData.millageTo}`}
                            data={activeModels}
                            onChangeFunction={(data) => {
                                setFormData({type: "model", payload: data.code});
                            }}
                            placeHolder={"გარგენი"}
                            disabled={false}
                            disabledBtnTitle={""}
                            type="millage"
                            setFormData={setFormData}
                            formData={formData}
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="catalogue-filter-item-box">
                        <YearsSelector 
                            active={(formData.yearFrom == "1920" && formData.yearTo == new Date().getFullYear()) ? "all" : `${formData.yearFrom} - ${formData.yearTo}`}
                            data={activeModels}
                            onChangeFunction={(data) => {
                                setFormData({type: "model", payload: data.code});
                            }}
                            placeHolder={"წელი"}
                            disabled={false}
                            disabledBtnTitle={""}
                            type="millage"
                            setFormData={setFormData}
                            formData={formData}
                        />
                    </div>
                    <div className="filter-divider"></div>
                    <div className="additional-filter-button" onClick={() => setShowSearchAdditionalSection(!showSearchAdditionalSection)}>
                        <img src="/static/icons/filter-icon.svg" alt="filter" />
                        <span>დამატებითი ფილტრი</span>
                    </div>
                </div>
                <button onClick={() => {
                    setLoading(true);
                    setCarsList([]);
                    loaders.filterResult();
                    setShowSearchAdditionalSection(false)
                }} className="filter-search-button">ძებნა</button>
            </div>
            {showSearchAdditionalSection && <div  className="detailed-search-container">
                <div className="catalogue-filter-item-box">
                    <MarkSelector 
                        active={formData.make.description}
                        data={marks}
                        parameterName={"description"}
                        onChangeFunction={(data) => {
                            setFormData({type: "make", payload: data});
                            let activeModelsList = models.filter((m) => m.group == data.code);
                            activeModelsList.sort((a, b) => {
                                if (a.description.toLowerCase() < b.description.toLowerCase()) {
                                  return -1;
                                }
                                if (a.description.toLowerCase() > b.description.toLowerCase()) {
                                  return 1;
                                }
                                return 0;
                            });
                            const uniqueArray = activeModelsList.filter((item, index, self) => 
                                index === self.findIndex((obj) => obj.description === item.description)
                            );
                            setActiveModels(uniqueArray);
                        }}
                        placeHolder={"მწარმოებელი"}
                        disabled={false}
                        disabledBtnTitle={""}
                        type="mark"
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <MarkSelector 
                        active={formData.model}
                        data={activeModels}
                        parameterName={"description"}
                        onChangeFunction={(data) => {
                            setFormData({type: "model", payload: data.code});
                        }}
                        placeHolder={"მოდელი"}
                        disabled={formData.make === ""}
                        disabledBtnTitle={"Please select make"}
                        type="models"
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <MillageSelector 
                        active={(formData.millageFrom == "0" && formData.millageTo == "999999") ? "all" : `${formData.millageFrom} - ${formData.millageTo}`}
                        data={activeModels}
                        onChangeFunction={(data) => {
                            setFormData({type: "model", payload: data.code});
                        }}
                        placeHolder={"გარბენი"}
                        disabled={false}
                        disabledBtnTitle={""}
                        type="millage"
                        setFormData={setFormData}
                        formData={formData}
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <MarkSelector 
                        active={formData.condition}
                        data={damageTypes}
                        parameterName={"description"}
                        onChangeFunction={(data) => {
                            setFormData({type: "condition", payload: formData.condition == data ? "" : data});
                        }}
                        placeHolder={"მდგომარეობა"}
                        disabled={false}
                        disabledBtnTitle={""}
                        type="with checkbox"
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <MarkSelector 
                        active={formData.fuel}
                        data={fuelTypes}
                        parameterName={"description"}
                        onChangeFunction={(data) => {
                            setFormData({type: "fuel", payload: formData.fuel == data ? "" : data});
                        }}
                        placeHolder={"საწვავის ტიპი"}
                        disabled={false}
                        disabledBtnTitle={""}
                        type="with checkbox"
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <MarkSelector 
                        active={formData.transmission}
                        data={[{description: "Automatic", code: "AUTOMATIC"}, {description: "Manual", code: "MANUAL"}]}
                        parameterName={"description"}
                        onChangeFunction={(data) => {
                            setFormData({type: "transmission", payload: formData.transmission == data ? "" : data});
                        }}
                        placeHolder={"ტრანსმისია"}
                        disabled={false}
                        disabledBtnTitle={""}
                        type="with checkbox"
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <MarkSelector 
                        active={formData.drive}
                        data={driveWheels}
                        parameterName={"description"}
                        onChangeFunction={(data) => {
                            setFormData({type: "drive", payload: formData.drive == data ? "" : data});
                        }}
                        placeHolder={"წამყვანი თვლები"}
                        disabled={false}
                        disabledBtnTitle={""}
                        type="with checkbox"
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <MarkSelector 
                        active={formData.sellType}
                        data={[{description: "Buy It Now", code: "Buy It Now"}]}
                        parameterName={"description"}
                        onChangeFunction={(data) => {
                            setFormData({type: "sellType", payload: formData.sellType == data ? "" : data});
                        }}
                        placeHolder={"გაყიდვის ტიპი"}
                        disabled={false}
                        disabledBtnTitle={""}
                        type="with checkbox"
                    />
                </div>
                <div className="catalogue-filter-item-box">
                    <svg onClick={() => {
                        setLoading(true);
                        setCarsList([]);
                        loaders.searchByKeyword();
                        setShowSearchAdditionalSection(false)
                    }} className="catalogue-filter-loop" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21.75 20.6895L16.086 15.0255C17.4471 13.3915 18.1258 11.2956 17.981 9.17392C17.8361 7.05222 16.8789 5.06804 15.3084 3.63413C13.7379 2.20023 11.675 1.427 9.54893 1.47531C7.42284 1.52362 5.39723 2.38974 3.89347 3.8935C2.38971 5.39726 1.52359 7.42288 1.47528 9.54896C1.42697 11.6751 2.2002 13.7379 3.6341 15.3084C5.06801 16.8789 7.05219 17.8362 9.17389 17.981C11.2956 18.1258 13.3914 17.4471 15.0255 16.086L20.6895 21.75L21.75 20.6895ZM2.99996 9.75C2.99996 8.41497 3.39585 7.10993 4.13754 5.9999C4.87924 4.88986 5.93345 4.0247 7.16685 3.51381C8.40025 3.00292 9.75745 2.86924 11.0668 3.12969C12.3762 3.39014 13.5789 4.03302 14.5229 4.97702C15.4669 5.92103 16.1098 7.12376 16.3703 8.43314C16.6307 9.74251 16.497 11.0997 15.9862 12.3331C15.4753 13.5665 14.6101 14.6207 13.5001 15.3624C12.39 16.1041 11.085 16.5 9.74996 16.5C7.96036 16.498 6.24463 15.7862 4.97919 14.5208C3.71375 13.2553 3.00195 11.5396 2.99996 9.75Z" fill="black"/>
                    </svg>
                    <input className="search-by-vin-lot" type="text" placeholder="შეიყვანეთ VIN კოდი ან LOT - ის ნომერი" value={formData.keyword} onChange={(e) => setFormData({type: "keyword", payload: e.target.value})}/>
                </div>
            </div>}
        </div>
    )
}

export default Filter