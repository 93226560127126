import { Link } from "react-router-dom/cjs/react-router-dom.min"
import WhyUs from "../components/MainPage/WhyUs/WhyUs"

const Dealers = () => {
    return(
        <div className="dealers-page-container">
            <div className="pager-history">
                <Link to="/">მთავარი</Link>
                <span> / </span>
                <Link to="/dealer-page">დილერის გვერდი</Link>
            </div>
            <WhyUs />
        </div>
    )
}

export default Dealers