import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom"

const NotFound = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
    }, [])
    
    return(
        <div className="page-not-found-container">
            <img src="/static/404.png" alt="page not found" className="page-not-found-image" />
            <h3>Oops! როგორც ჩანს დაიკარგე.</h3>
            <p>გვერდს, რომელსაც ეძებთ მიუწვდომელია. სცადეთ თავიდან ან დაბრუნდით მთავარ გვერდზე.</p>
            <Link to="/">მთავარზე დაბრუნება</Link>
        </div>
    )
}

export default NotFound