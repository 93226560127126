import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import "./LoggedInUsers.css"
import PersonalDetails from './PersonalDetails'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const LoggedInUsers = () => {
    return(
        <div className="personal-pages-container">
            <h3 className='personal-pages-title'>პირადი მონაცემები</h3>
            <div className="personal-page-page-switcher-box">
                <Link to="/personal-cabinet/personal-details">
                    <div>
                        <svg className="profile-dropdown-list-menu-item-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M4.80002 5.30011C4.79992 4.63703 5.0058 3.99029 5.38921 3.4493C5.77262 2.90831 6.31461 2.49978 6.94028 2.28018C7.56594 2.06059 8.24437 2.04078 8.88178 2.22349C9.51919 2.4062 10.0841 2.78241 10.4984 3.30011H8.00002V2.90011C7.3635 2.90011 6.75306 3.15297 6.30297 3.60305C5.85288 4.05314 5.60002 4.66359 5.60002 5.30011C5.60002 5.93663 5.85288 6.54708 6.30297 6.99717C6.75306 7.44725 7.3635 7.70011 8.00002 7.70011V7.30011H10.4984C10.0841 7.81781 9.51919 8.19402 8.88178 8.37673C8.24437 8.55944 7.56594 8.53963 6.94028 8.32004C6.31461 8.10045 5.77262 7.69192 5.38921 7.15092C5.0058 6.60993 4.79992 5.96319 4.80002 5.30011ZM10.968 6.50011H8.00002V5.70011H11.176C11.1392 5.98064 11.0699 6.24731 10.968 6.50011ZM11.176 4.90011C11.1416 4.62557 11.0717 4.35666 10.968 4.10011H8.00002V4.90011H11.176ZM2.40002 10.9001C2.40002 10.0097 3.12242 9.30011 4.00722 9.30011H12C12.2802 9.29999 12.5555 9.37345 12.7984 9.51315C13.0413 9.65284 13.2432 9.85387 13.384 10.0961H8.00002V10.1001H4.00722C3.55842 10.1001 3.20002 10.4577 3.20002 10.9001C3.20002 11.9473 3.69762 12.7273 4.53842 13.2625C5.39442 13.8089 6.61202 14.1001 8.00002 14.1001H11.616C10.6256 14.6425 9.35522 14.9001 8.00002 14.9001C6.51602 14.9001 5.13362 14.5913 4.10802 13.9377C3.06642 13.2737 2.40002 12.2521 2.40002 10.9001ZM8.00002 11.6961V10.8961H13.6V10.9001C13.5995 11.1801 13.5718 11.4454 13.5168 11.6961H8.00002ZM13.2352 12.4961C13.0859 12.7899 12.8952 13.0606 12.6688 13.3001H11.4024L11.408 13.2961H8.00002V12.4961H13.2352Z" fill="black"/>
                        </svg>
                    </div>
                    <p>პირადი მონაცემები</p>
                </Link>
                <Link to="/personal-cabinet/won-cars">
                    <div>
                        <svg className="profile-dropdown-list-menu-item-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <g clipPath="url(#clip0_764_1647)">
                            <path d="M0.571411 15.6887H9.14284M7.99998 15.6887V12.8316H1.71427V15.6887M10.2857 7.11728L15.4286 12.2601M8.89941 1.64528L4.80227 5.74243C4.58802 5.95674 4.46765 6.24738 4.46765 6.55043C4.46765 6.85347 4.58802 7.14411 4.80227 7.35842L6.61255 9.16871C6.82687 9.38296 7.11751 9.50332 7.42055 9.50332C7.7236 9.50332 8.01424 9.38296 8.22855 9.16871L12.3257 5.07157C12.5399 4.85725 12.6603 4.56661 12.6603 4.26357C12.6603 3.96052 12.5399 3.66989 12.3257 3.45557L10.5143 1.64528C10.3 1.43103 10.0093 1.31067 9.70627 1.31067C9.40322 1.31067 9.11258 1.43103 8.89827 1.64528H8.89941Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_764_1647">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.500122)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p>მოგებული მანქანები</p>
                </Link>
                <Link to="/personal-cabinet/saved-cars">
                    <div>
                        <svg className="profile-dropdown-list-menu-item-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                            <g clip-path="url(#clip0_764_1652)">
                                <path d="M9.39068 12.5001C9.15162 12.5001 8.91677 12.4313 8.71134 12.301L6.118 10.6544C6.04707 10.6092 5.95303 10.6098 5.88262 10.6544L3.28875 12.301C2.80486 12.6084 2.13761 12.5369 1.72728 12.1455C1.47562 11.9049 1.33691 11.5791 1.33691 11.2281V1.77052C1.33744 1.07017 1.90749 0.500122 2.60784 0.500122H9.39278C10.0931 0.500122 10.6632 1.07017 10.6632 1.77052V11.2281C10.6632 11.9295 10.0926 12.5001 9.39068 12.5001ZM6.00031 9.56947C6.24199 9.56947 6.47789 9.63777 6.68175 9.76755L9.27509 11.4136C9.31239 11.4372 9.35075 11.4488 9.3912 11.4488C9.48052 11.4488 9.61292 11.3789 9.61292 11.2281V1.77052C9.61239 1.64968 9.51362 1.55091 9.39278 1.55091H2.60784C2.48648 1.55091 2.38823 1.64968 2.38823 1.77052V11.2281C2.38823 11.2912 2.41029 11.3437 2.4539 11.3852C2.52588 11.4541 2.63569 11.4709 2.72606 11.4136L5.3194 9.76702C5.52273 9.63778 5.75863 9.56947 6.00031 9.56947Z" fill="black"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_764_1652">
                                    <rect width="12" height="12" fill="white" transform="translate(0 0.500122)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p>შენახული მანქანები</p>
                </Link>
                <Link to="/personal-cabinet/balance">
                    <div>
                        <svg className="profile-dropdown-list-menu-item-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M13 5.00012H3C2.17157 5.00012 1.5 5.67169 1.5 6.50012V12.5001C1.5 13.3285 2.17157 14.0001 3 14.0001H13C13.8284 14.0001 14.5 13.3285 14.5 12.5001V6.50012C14.5 5.67169 13.8284 5.00012 13 5.00012Z" stroke="black" stroke-linejoin="round"/>
                            <path d="M12.855 5.00011V4.06261C12.8549 3.83267 12.8041 3.60559 12.7062 3.39755C12.6082 3.18952 12.4656 3.00567 12.2884 2.85912C12.1112 2.71256 11.9039 2.60692 11.6812 2.54972C11.4585 2.49253 11.2259 2.48519 11 2.52823L2.77 3.93292C2.4126 4.00103 2.09017 4.19173 1.85833 4.47212C1.62648 4.75251 1.49975 5.10503 1.5 5.46886V7.00011" stroke="black" stroke-linejoin="round"/>
                            <path d="M11.5 10.5001C11.3022 10.5001 11.1089 10.4415 10.9444 10.3316C10.78 10.2217 10.6518 10.0655 10.5761 9.88281C10.5004 9.70008 10.4806 9.49901 10.5192 9.30503C10.5578 9.11105 10.653 8.93287 10.7929 8.79302C10.9327 8.65316 11.1109 8.55792 11.3049 8.51934C11.4989 8.48075 11.7 8.50056 11.8827 8.57624C12.0654 8.65193 12.2216 8.7801 12.3315 8.94455C12.4414 9.109 12.5 9.30234 12.5 9.50012C12.5 9.76534 12.3946 10.0197 12.2071 10.2072C12.0196 10.3948 11.7652 10.5001 11.5 10.5001Z" fill="black"/>
                        </svg>
                    </div>
                    <p>ბალანსის შევსება</p>
                </Link>
                <Link to="/personal-cabinet/transaction-history">
                    <div>
                        <svg className="profile-dropdown-list-menu-item-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M1.33337 5.16677H13.3334M10.6667 1.83344L14 5.16677L10.6667 8.5001M14.6667 11.8334H2.66671M5.33337 8.5001L2.00004 11.8334L5.33337 15.1668" stroke="black"/>
                        </svg>
                    </div>
                    <p>ტრანზაქციების ისტორია</p>
                </Link>
            </div>
            <div className="personal-pages-content-box">
                <Route path='/personal-cabinet/personal-details'><PersonalDetails /></Route>
            </div>
        </div>
    )
}

export default LoggedInUsers