/* eslint-disable */
import { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const YearsSelector = ({active, placeHolder, disabled, disabledBtnTitle, formData, setFormData, type, onChangeFunction}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [years, setYears] = useState({from: "1920", to: new Date().getFullYear()});
    const yearsCount = new Date().getFullYear() - 1920
    const yearsList = new Array(yearsCount + 1).fill('DefaultValue');

    useEffect(() => {
        setYears({from: formData.yearFrom, to: formData.yearTo})
    }, [])

    return(
        <div className="form-control-select">
            <div className={`form-control-select-box ${disabled ? 'disabled-dropdown' : ''}`} title={disabled ? disabledBtnTitle : ""} onClick={() => {
                    if(!disabled) setShowDropdown(!showDropdown);
                }}>
                    <p>{active === "all" ? placeHolder : active}</p>
                    <img src="/static/icons/down-icon.svg" alt="down" className="select-dropdown-icon" />
            </div>
            {(showDropdown) && <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
                <div className="target-box"></div>
                <div className="form-control-select-dropdown millage-selector years-selector">
                    {
                        type == "birthDate" ? <>
                            {
                                yearsList.map((i, index) => {
                                    return <div onClick={() => {
                                        onChangeFunction(new Date().getFullYear() - index);
                                        setShowDropdown(false);
                                    }} className="years-item-signup" key={new Date().getFullYear() - index}>{new Date().getFullYear() - index}</div>
                                })
                            }
                        </> : (
                            <>
                                <div className="millage-input-range-content">
                                    <div className="millage-input-box">
                                        <span>დან</span>
                                        <input value={`${years.from}`} min={"1920"} max={new Date().getFullYear()} onChange={(e) => setYears((prev) => ({...prev, from: e.target.value}))} type="text" className="no-border"/>
                                    </div>
                                    <div className="millage-input-box">
                                        <span>მდე</span>
                                        <input value={`${years.to}`} min={"1920"} max={new Date().getFullYear()} onChange={(e) => setYears((prev) => ({...prev, to: e.target.value}))} type="text" className="no-border"/>
                                    </div>
                                </div>
                                <div className="range-slider-two-switcher">
                                    <ul className="range-selector-dropdown-column">
                                        {
                                            yearsList.map((i, index) => {
                                                return <li onClick={() => setYears((prev) => ({...prev, from: 1920 + index}))} className={years.from == 1920 + index ? "active" : ""} key={index}>{1920 + index}</li>
                                            })
                                        }
                                    </ul>
                                    <ul className="range-selector-dropdown-column">
                                        {
                                            yearsList.map((i, index) => {
                                                return <li onClick={() => setYears((prev) => ({...prev, to: new Date().getFullYear() - index}))} className={years.to == new Date().getFullYear() - index ? "active" : ""} key={new Date().getFullYear() - index}>{new Date().getFullYear() - index}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                    <button className="save-millage-button" onClick={() => {
                                        setFormData({type: "year_from", payload: years.from});
                                        setFormData({type: "year_to", payload: years.to});
                                        setShowDropdown(false);
                                    }}>შენახვა</button>
                                </div>
                            </>
                        )
                    }
                </div>
            </OutsideClickHandler>}
        </div>
    )
}

export default YearsSelector