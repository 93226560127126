/* eslint-disable */
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const MarkSelector = ({active, data, onChangeFunction, parameterName, placeHolder, disabled, disabledBtnTitle, type}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return(
        <div className="form-control-select">
            <div className={`form-control-select-box ${disabled ? 'disabled-dropdown' : ''}`} title={disabled ? disabledBtnTitle : ""} onClick={() => {
                    if(!disabled) setShowDropdown(!showDropdown);
                }}>
                    <p>{active === "" ? placeHolder : active}</p>
                    <img src="/static/icons/down-icon.svg" alt="down" className="select-dropdown-icon" />
            </div>
            {(showDropdown && !disabled) && <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
                <div className="target-box"></div>
                <div className="form-control-select-dropdown mark-selector-dropdown">
                    {type == "with checkbox" ? (
                        data.map((item) => {
                            return <div onClick={() => onChangeFunction(item.code)} className="list-with-checkbox">
                                {active == item.code ? <img src="/static/icons/checkboxWhite.svg" className="dropdown-checkbox" alt="checkbox"/> : (
                                    <svg style={{marginRight: "8px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <rect x="1" y="1.00008" width="18" height="18" rx="5" stroke="#E6E6E6" strokeWidth="2"/>
                                    </svg>
                                )}
                                <span>{item.description}</span>
                            </div>
                        })
                    ) : <>
                        {
                            type == "mark" && <>
                                <p className="top-brands-title">Top ბრენდები</p>
                                <div className="top-brands-list">
                                    <div className="top-brands-item"><img src="/static/icons/aston-martin.svg"/></div>
                                    <div className="top-brands-item"><img src="/static/icons/ford.svg"/></div>
                                    <div className="top-brands-item"><img src="/static/icons/mercedes.svg"/></div>
                                    <div className="top-brands-item"><img src="/static/icons/subaru.svg"/></div>
                                    <div className="top-brands-item"><img src="/static/icons/audi.svg"/></div>
                                </div>
                            </>
                        }
                        <div onClick={() => {
                                    onChangeFunction("default");
                                    setShowDropdown(false);
                                }}  className="form-control-dropdown-item"><p className="form-control-dropdown-text">{placeHolder}</p></div>
                        {
                            data.map((d) => {
                                return <div onClick={() => {
                                    onChangeFunction(d);
                                    setShowDropdown(false);
                                }}  className="form-control-dropdown-item"><p className="form-control-dropdown-text">{d[parameterName]}</p></div>
                            })
                        }
                    </>}
                </div>
            </OutsideClickHandler>}
        </div>
    )
}

export default MarkSelector