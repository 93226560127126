/* eslint-disable */
import { useEffect, useState } from "react"
import "../stylesheet/Auth.css"
import { usersActions } from "../store/user"
import { useDispatch } from 'react-redux'
import axios from "axios"

const Auth = ({setShowAuth, setShowSignup}) => {
    const [focused, setFocused] = useState({
        email: false, password: false
    })
    const [shown, setShown] = useState(false);
    const [formData, setFormData] = useState({email: "", password: ""});
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({email: false, password: false});
    const [messageText, setMessageText] = useState("");

    useEffect(() => {
        setShown(true)
    }, [])

    const hideModal = () => {
        setShown(false);
        setTimeout(() => {
            setShowAuth(false)
        }, 1000);
    }

    const handlers = {
        async login(){
            try{
                const response = await axios.post('/api/login', {user: formData.email, password: formData.password});
                window.location.reload()
            }catch(err){
                const message = err.response.data.message;
                setMessageText(message.geo)
                if(message.en == "Wrong password"){
                    setErrors((prev) => ({email: false, password: true}));
                }else{
                    setErrors((prev) => ({password: false, email: true}));
                }
            }
        }
    }

    return(
        <div className={`auth-page-container ${shown ? "auth-animation" : ""}`}>
            <div className="auth-page-content">
                <img onClick={hideModal} className="auth-page-logo" src="/static/icons/logo.png" alt="auth logo"/>
                <h2 className="auth-page-title">შესვლა</h2>
                <div className="form-group form-field-divided">
                    <label className={`form-label ${focused.email || (!focused.email && formData.email != "") ? "label-blured" : ""}`}>@ ელ. ფოსტა</label>
                    <input 
                        className="form-control" 
                        onBlur={() => setFocused((prev) => ({...prev, email: false}))}
                        onFocus={() => setFocused((prev) => ({...prev, email: true}))}
                        onChange={(e) => {
                            setFormData((prev) => ({...prev, email: e.target.value}));
                            setErrors((prev) => ({...prev, email: false}));
                        }}
                        value={formData.email}
                    />
                    {errors.email && <p className="error-text">{messageText}</p>}
                </div>
                <div className="form-group form-field-divided">
                    <label className={`form-label-with-icon ${focused.password || (!focused.password && formData.password != "") ? "label-blured" : ""}`}>პაროლი</label>
                    <div className="input-with-two-icon">
                        <svg style={{position: "absolute", marginLeft: "16px", zIndex: 11111}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M15.8333 9.16667H4.16667C3.24619 9.16667 2.5 9.91286 2.5 10.8333V16.6667C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6667V10.8333C17.5 9.91286 16.7538 9.16667 15.8333 9.16667Z" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5.83337 9.16667V5.83334C5.83337 4.72827 6.27236 3.66846 7.05376 2.88706C7.83516 2.10566 8.89497 1.66667 10 1.66667C11.1051 1.66667 12.1649 2.10566 12.9463 2.88706C13.7277 3.66846 14.1667 4.72827 14.1667 5.83334V9.16667" stroke="#999999" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <input
                            type={!showPassword ? "password" : "text"}
                            className="form-control" 
                            onBlur={() => setFocused((prev) => ({...prev, password: false}))}
                            onFocus={() => setFocused((prev) => ({...prev, password: true}))}
                            onChange={(e) => {
                                setFormData((prev) => ({...prev, password: e.target.value}));
                                setErrors((prev) => ({...prev, password: false}));
                            }}
                            value={formData.password}
                            style={{paddingLeft: "49px"}}
                        />
                        <svg onClick={() => setShowPassword(!showPassword)} style={{position: "absolute", marginLeft: "100%", cursor: "pointer", transform: "translateX(-32px)", zIndex: 1111, marginRight: "16px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_631_838)">
                            <path d="M14.8168 1.1824C14.6668 1.03242 14.4633 0.948166 14.2512 0.948166C14.0391 0.948166 13.8356 1.03242 13.6856 1.1824L1.184 13.6856C1.10759 13.7594 1.04665 13.8477 1.00472 13.9453C0.962792 14.0429 0.940723 14.1479 0.9398 14.2541C0.938877 14.3603 0.959118 14.4656 0.999343 14.564C1.03957 14.6623 1.09897 14.7516 1.17408 14.8267C1.2492 14.9018 1.33852 14.9612 1.43684 15.0015C1.53515 15.0417 1.6405 15.0619 1.74672 15.061C1.85294 15.0601 1.95792 15.038 2.05552 14.9961C2.15313 14.9542 2.2414 14.8932 2.3152 14.8168L14.816 2.3136C14.966 2.16357 15.0502 1.96013 15.0502 1.748C15.0502 1.53586 14.966 1.33242 14.816 1.1824H14.8168ZM2.4864 10.7984L4.5344 8.7504C4.476 8.50448 4.44568 8.25274 4.444 8C4.444 6.0968 6.036 4.5528 8 4.5528C8.2288 4.5528 8.4512 4.57839 8.668 4.61839L9.6304 3.6568C9.0915 3.56752 8.54625 3.52203 8 3.5208C2.752 3.52 0 7.3848 0 8C0 8.3384 0.8456 9.672 2.4864 10.7976V10.7984ZM13.516 5.204L11.4664 7.25199C11.5216 7.49359 11.5552 7.7424 11.5552 8C11.5552 9.9032 9.964 11.4456 8 11.4456C7.7728 11.4456 7.552 11.42 7.3368 11.3808L6.3736 12.3432C6.8872 12.4264 7.4264 12.4792 8 12.4792C13.248 12.4792 16 8.6128 16 8C16 7.6608 15.1552 6.328 13.516 5.204Z" fill="#999999"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_631_838">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    {errors.password && <p className="error-text">{messageText}</p>}
                </div>
                <p className="reset-password-text">დაგავიწყდათ პაროლი?</p>
                <button className="login-form-submit-button" onClick={handlers.login}>ავტორიზაცია</button>
                <button className="login-form-signup-button" onClick={() => {
                    setShowSignup(true);
                    setShowAuth(false);
                }}>რეგისტრაცია</button>
            </div>
            <div className="auth-page-banner"></div>
        </div>
    )
}

export default Auth