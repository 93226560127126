import TextEditor from "../../components/TextEditor/TextEditor"
import { EditorState } from 'draft-js';
import "./stylesheets/CreateBlog.css"
import { useEffect, useReducer, useState } from "react";
import { blogReducer, blogsInitialState } from "./reducer/blogsReducer";
import axios from "axios";

const CreateBlog = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [htmlContent, setHtmlContent] = useState("");
    const [formData, dispatchData] = useReducer(blogReducer, blogsInitialState);
    const [convertedToHtml, setConvertedToHtml] = useState(false);

    const saveBlog = () => {
        if(convertedToHtml){
            axios.post('/api/blogs', {...formData, html: htmlContent})
                .then((res) => {
                    alert("save")
                })
                .catch((err) => {
                    alert("err")
                })
        }else{
            alert("შეინავეთ ცვლილებები")
        }
    }

    useEffect(() => {
        setConvertedToHtml(false);
    }, [editorState])

    return(
        <div className="create-blog-container">
            <div className="blogs-form-group">
                <label>სათაური</label>
                <input placeholder="ჩაწერეთ ბლოგის სათაური" className="blogs-form-control" value={formData.title} onChange={(e) => dispatchData({type: "title", payload: e.target.value})}/>
            </div>
            <div className="blogs-form-group">
                <label>აღწერა</label>
                <input placeholder="ჩაწერეთ ბლოგის მოკლე არწერა" className="blogs-form-control" value={formData.description} onChange={(e) => dispatchData({type: "description", payload: e.target.value})}/>
            </div>
            <div className="blogs-form-group">
                <label>ავტორი</label>
                <input placeholder="ჩაწერეთ ბლოგის ავტორის სახელი" className="blogs-form-control" value={formData.author} onChange={(e) => dispatchData({type: "author", payload: e.target.value})}/>
            </div>
            <div className="blogs-form-group">
                <label>პროფილის სურათი</label>
                <input placeholder="ჩასვით სურათის ლინკი" className="blogs-form-control" value={formData.profile} onChange={(e) => dispatchData({type: "profile", payload: e.target.value})}/>
            </div>
            <div className="blogs-form-group">
                <label>ბლოგის კონტენტი</label>
                <TextEditor setConvertedToHtml={setConvertedToHtml} setHtmlContent={setHtmlContent} editorState={editorState} setEditorState={setEditorState}/>
            </div>
            <button onClick={saveBlog} className="save-blog-button">ბლოგის დამატება</button>
        </div>
    )
}

export default CreateBlog