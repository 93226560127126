import { Link } from "react-router-dom/cjs/react-router-dom"
import "./NewsCard.css"

const NewsCard = ({data}) => {

    const d = {
        title: "რა ფუნქციები უნდა ვეძებოთ ახალ ავტომობილში",
        description: "ახალი ავტომობილის შეძენა შეიძლება იყოს ამაღელვებელი, მაგრამ ამავე…",
        date: "Jul 12, 2024",
        time: "8PM",
        image: "https://carspace-app.crg.ge/static/news/news1.webp"
    }

    return(
        <Link to={`/blog/${data.id}`} className="news-card-container" style={{backgroundImage: `url('${data.profile}')`}}>
            <div className="news-tooltip">
                <span>News</span>
            </div>
            <div className="news-card-details">
                <h4 className="news-card-title">{data.title}</h4>
                <p className="news-card-description">{data.description.slice(0, 65)}{data.description.length > 65 ? "…" : ""}</p>
                <div className="news-card-publish-date-and-time">
                    <span>{data.date.slice(0,10)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.1005 9.8995 1.75 7 1.75C4.1005 1.75 1.75 4.1005 1.75 7C1.75 9.8995 4.1005 12.25 7 12.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M7 3.5V7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.47333 9.47333L7 7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>8PM</span>
                </div>
            </div>
        </Link>
    )
}

export default NewsCard